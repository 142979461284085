/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react"

// Create the layout component
class OwlerLayout extends React.Component {
  render() {
    const {
      getComponent
    } = this.props

    const BaseLayout = getComponent("BaseLayout", true)
    return (
      <div>
        <div className="owler-ui">
          <div className="owler-topbar">
              <a href="https://www.owler.com/enterprise/" className="left-content">
              <img src="https://owler-image.s3.amazonaws.com/assets/v2/logos/owler-mw_logo-300x57.png" width="298" height="52" alt="owler logo" className="logo" />
              </a>
              <div className="right-content">
                <a href="https://corp.owler.com/contact-sales">
              <button className="contact-button">
                <p>Book a meeting</p>
              </button>
              </a>
              </div>
          </div>
          <BaseLayout />
        </div>
      </div>
    )
  }
}

// Create the plugin that provides our layout component
export const OwlerLayoutPlugin = () => {
  return {
    components: {
      OwlerLayout: OwlerLayout,
      Topbar: false
    }
  }
}


// // Provide the plugin to Swagger-UI, and select AugmentingLayout
// // as the layout for Swagger-UI
// SwaggerUI({
//   url: "https://petstore.swagger.io/v2/swagger.json",
//   plugins: [ OwlerLayoutPlugin ],
//   layout: "AugmentingLayout"
// })